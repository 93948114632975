var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c("div", { staticClass: "equipment" }, [
        _c("div", { staticClass: "equipment-main" }, [
          _c("section", [
            _c("img", {
              staticClass: "title",
              attrs: { src: require("@/assets/map/detail-title.png"), alt: "" },
            }),
            _c("i", {
              staticClass: "el-icon-circle-close icon",
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            }),
          ]),
          _vm._m(0),
          _c(
            "section",
            { staticClass: "equipment-main-search" },
            [
              _c("span", { staticClass: "build" }, [_vm._v("楼栋：")]),
              _c(
                "el-select",
                {
                  staticClass: "select",
                  attrs: { placeholder: "请选择楼栋" },
                  on: {
                    change: function ($event) {
                      return _vm.contractEntityQuery()
                    },
                  },
                  model: {
                    value: _vm.buildingValue,
                    callback: function ($$v) {
                      _vm.buildingValue = $$v
                    },
                    expression: "buildingValue",
                  },
                },
                _vm._l(_vm.options, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
              _c("el-input", {
                staticClass: "input",
                attrs: { placeholder: "请输入搜索内容" },
                model: {
                  value: _vm.projName,
                  callback: function ($$v) {
                    _vm.projName = $$v
                  },
                  expression: "projName",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.contractEntityQuery()
                    },
                  },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "equipment-main-table" },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.tableData, border: "", height: "460" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "number",
                      "show-overflow-tooltip": "",
                      label: "订单",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "serialNumber",
                      label: "序列号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "name", label: "商品" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "model",
                      "show-overflow-tooltip": "",
                      label: "型号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "barcode", label: "条码" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "depotName",
                      label: "仓库",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "supplierId",
                      label: "供应商/客户",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      align: "center",
                      label: "溯源标识",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "a",
                                {
                                  staticStyle: { color: "#06D5f5" },
                                  attrs: {
                                    href: `https://www.huaxucloud.com/traceabilityNew?handle=${scope.row.prefix}/${scope.row.handle}`,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${scope.row.prefix}/${scope.row.handle}`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3930204236
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "equipment-main-back" },
            [
              _c(
                "span",
                {
                  staticClass: "toBack mouseStyle",
                  on: { click: _vm.backDetail },
                },
                [_vm._v("返回合同信息")]
              ),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  total: _vm.total,
                  "page-size": 8,
                },
                on: { "current-change": _vm.changePage },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "equipment-main-title" }, [
      _c("div", { staticClass: "line-left" }),
      _c("span", { staticClass: "line-tip" }, [_vm._v("铝模板溯源模信息")]),
      _c("div", { staticClass: "line-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }